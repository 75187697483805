import { Injectable } from "@angular/core";
import { HttpHandler, HttpRequest, HttpInterceptor, HttpEvent } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
// import {ErrorService} from '../my-services/error.service';

@Injectable({
  providedIn: "root",
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private translate: TranslateService, private router: Router, private translateService: TranslateService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(error => {
        localStorage.removeItem("error404");
        let errorMessage = "";
        console.log(error.error);
        if (error.status === 401) {
          /*this.openIdConnectService.triggerSignOut();
          console.log('logout');*/
          //this.openIdConnectService.triggerSigmIn();
          //console.log("triggerSigmIn");
        } else {
          var notResultsFound = false;
          if (error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Client-side error: ${error.error.message}`;
          } else {
            // backend error
            errorMessage = `Server-side error: ${error.status} ${error.message}`;
            if(error?.error?.title?.toUpperCase() == "NO RESULTS FOUND") notResultsFound = true;
          }
          // aquí podrías agregar código que muestre el error en alguna parte fija de la pantalla.
          if (error.error === null) {
            console.log(error.message);

            this.router.navigate(["/" + this.translateService.currentLang + "/404"]);
          } else {
            console.error("error", error);
            if(error?.error?.value?.length > 0){
              let msg = error?.error?.value?.toLowerCase().trim().replaceAll(" ","_").replaceAll("\n","");
              let msgTranslated = this.translate.instant("emptyStates." + msg);
              console.log("msg", msg, "msgTranslated", msgTranslated);
              if(!msgTranslated.includes("emptyStates.")) {
                localStorage.setItem("error404", msg);
              } else {
                console.log("segment_sell_failure", msg?.includes("segment_sell_failure"));
                if(msg?.includes("segment_sell_failure")) msg = "segment_sell_failure";
                let msgTranslated = this.translate.instant("emptyStates." + msg);
                if(!msgTranslated.includes("emptyStates.")) localStorage.setItem("error404", msg);
              }
            }

            if(!notResultsFound){
              this.router.navigate(["/" + this.translateService.currentLang + "/404"]);
            }
          }

          return throwError(errorMessage);
        }
      })
    );
  }

}
