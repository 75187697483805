import { Injectable } from "@angular/core";
import { Passengers, Itineraries, LowFareGenericRespDto, MarkupRuleApplied, LowFareRespList } from "../models/low-fare-respond.model";
import { LowFareSearchDto, AirLine, AirPorts, UserDto } from "../models/low-fare-search.model";
import { AirPriceReq } from "../models/air-price-req";
import { AirPriceRsp } from "../models/air-price-rsp";
import { AirBookReq } from "../models/air-book-req";
import { AirBookRsp } from "../models/air-book-rsp";
import { GDS } from "../models/fare-rule-engine";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  lowFareSearch: LowFareSearchDto;
  lowFareGResp: Array<LowFareGenericRespDto>;
  lowFareDisplayList: Array<LowFareRespList>;
  //public lowFareResp: Array<LowFareResp>;
  airPriceReq: AirPriceReq;
  airPriceRsp: AirPriceRsp;
  airBookReq: AirBookReq;
  airBookRsp: AirBookRsp;
  // public airRetrieve: AirCreateReservationRsp;
  origin: Array<string>;
  destination: Array<string>;
  via: Array<string>;
  permittedCabins: Array<string>;
  departureDate: Array<Date>;
  passengers: Array<Passengers>;
  traceId: string;
  priceReconfirmation = false;
  onBack = false;
  nonStop = false;
  refundable = false;
  baggageIncluded = false;
  extraDays = false;
  GDS: string;
  displayGDSList: Array<GDS>;
  selectedGDSList: Array<GDS>;
  departureTime: Array<string>;
  returnTime: string;
  returnDate: Date;
  connectionType: string;
  itineraries: Array<Itineraries>;
  lowFare: LowFareGenericRespDto;
  dataTest = true;
  childsDOB: Array<Date>;
  infantsDOB: Array<Date>;
  numAdult = 1;
  oneWay = false;
  roundTrip = true;
  itineraryMultiple = false;
  airlines: Array<string>;
  fareOption = 0;
  lowFareSearchValues: any;
  lowFareSearchExtraValues: any;
  currentMarkUp: Array<MarkupRuleApplied>;
  currentMarkUpFlightCombined: Array<MarkupRuleApplied>;
  lowFareRespChange = new Subject<Array<LowFareGenericRespDto>>();
  pendingGDS: number;
  messageIA: string;
  
  public constructor() {
    this.lowFareRespChange.subscribe(value => {
      if (this.lowFareGResp == null) this.lowFareGResp = new Array<LowFareGenericRespDto>();
      this.lowFareGResp.push(...value);
    });

    // Retrieving parameters data from local storage and setting in this service:
    this.setFromLocal(
      JSON.parse(localStorage.getItem("lowFareSearchPersistance")),
      JSON.parse(localStorage.getItem("GDSListPersistance"))
    );

    const userDto: UserDto = JSON.parse(localStorage.getItem("userStorage"));
    if (userDto !== null) {
      if (userDto.gds !== null) this.setDisplayGDSListFromLocal(userDto.gds);
      if (userDto.isGdsMandatory) this.setSelectedGDSListFromLocal(userDto.gds);
    }
  }

  setMessageIA(message: string)
  {
    this.messageIA = message;
  }
  
  getMessageIA()
  {
    return this.messageIA;
  }

  setFareOption(data: number) {
    this.fareOption = data;
  }

  getFareOption() {
    return this.fareOption;
  }

  /*setLowFareResp(data: Array<LowFareResp>) {
    this.lowFareResp = data;
  }
  getLowFareResp() {
    return this.lowFareResp;
  }*/
  setAirlines(data: Array<string>) {
    this.airlines = data;
  }

  getAirlines() {
    return this.airlines;
  }

  setConnectionType(data: string) {
    this.connectionType = data;
  }

  getConnectionType() {
    return this.connectionType;
  }

  setNumAdult(data: number) {
    this.numAdult = data;
  }

  getNumAdult() {
    return this.numAdult;
  }

  setItineraryMultiple(data: boolean) {
    this.itineraryMultiple = data;
  }

  getItineraryMultiple() {
    return this.itineraryMultiple;
  }

  setRoundTrip(data: boolean) {
    this.roundTrip = data;
  }

  getRoundTrip() {
    return this.roundTrip;
  }

  setOneWay(data: boolean) {
    this.oneWay = data;
  }

  getOneWay() {
    return this.oneWay;
  }

  setRefundable(data: boolean) {
    this.refundable = data;
  }

  getRefundable() {
    return this.refundable;
  }

  setBaggageIncluded(data: boolean) {
    this.baggageIncluded = data;
  }

  getBaggageIncluded() {
    return this.baggageIncluded;
  }

  setChildsDO(data: Array<Date>) {
    this.childsDOB = data;
  }

  getChildsDOB() {
    return this.childsDOB;
  }

  setInfantsDOB(data: Array<Date>) {
    this.infantsDOB = data;
  }

  getInfantsDOB() {
    return this.infantsDOB;
  }

  setDataTest(data: boolean) {
    this.dataTest = data;
  }

  getDataTest() {
    return this.dataTest;
  }

  setAirBookRsp(data: any) {
    this.airBookRsp = data;
  }

  getAirBookRsp() {
    return this.airBookRsp;
  }

  setAirBookReq(data: any) {
    this.airBookReq = data;
  }

  getAirBookReq() {
    return this.airBookReq;
  }

  setLowFare(data: any) {
    this.lowFare = data;
  }

  getLowFare() {
    return this.lowFare;
  }

  setPassengers(data: Array<Passengers>) {
    this.passengers = data;
  }

  getPassengers() {
    return this.passengers;
  }

  setItineraries(data: Array<Itineraries>) {
    this.itineraries = data;
  }

  getItineraries() {
    return this.itineraries;
  }

  setAirPriceReq(data: AirPriceReq) {
    this.airPriceReq = data;
  }

  getAirPriceReq() {
    return this.airPriceReq;
  }

  setAirPriceRsp(data: any) {
    this.airPriceRsp = data;
  }

  getAirPriceRsp() {
    return this.airPriceRsp;
  }

  setLowFareSearch(data: LowFareSearchDto) {
    this.lowFareSearch = data;
  }

  getLowFareSearch() {
    return this.lowFareSearch;
  }

  setOrigin(data: Array<string>) {
      this.origin = data;
  }

  getOrigin() {
    return this.origin;
  }

  setDestination(data: Array<string>) {
    this.destination = data;
  }

  getDestination() {
    return this.destination;
  }

  setVia(data: Array<string>) {
    this.via = data;
  }

  getVia() {
    return this.via;
  }

  setPermittedCabins(data: Array<string>) {
    this.permittedCabins = data;
  }

  getPermittedCabins() {
    return this.permittedCabins;
  }

  setDepartureDate(data: Array<Date>) {
    this.departureDate = data;
  }

  getDepartureDate() {
    return this.departureDate;
  }

  setLowFareGResp(data: Array<LowFareGenericRespDto>) {
    this.lowFareGResp = data;
  }

  addLowFareGResp(data: Array<LowFareGenericRespDto>) {
    this.lowFareRespChange.next(data);
  }

  getLowFareGResp() {
    return this.lowFareGResp;
  }
  setLowFareDisplayList(data: any){
    this.lowFareDisplayList?.forEach(x =>  {
      x.flights.forEach(element => {
        element.isSameFlight = false
      });
      x.sameFlights = [];
   });
    this.lowFareDisplayList = data;
  }
  getLowFareDisplayList(){
    return this.lowFareDisplayList;
  }

  getNonStop() {
    return this.nonStop;
  }

  setNonStop(data: boolean) {
    this.nonStop = data;
  }

  getExtraDays() {
    return this.extraDays;
  }

  setExtraDays(data: boolean) {
    this.extraDays = data;
  }

  getGDS() {
    return this.GDS;
  }

  setGDS(data: string) {
    this.GDS = data;
  }

  getDisplayGDSList() {
    return this.displayGDSList;
  }

  setDisplayGDSList(data: Array<GDS>) {
    this.displayGDSList = data;
  }

  getSelectedGDSList() {
    return this.selectedGDSList;
  }

  setSelectedGDSList(data: Array<GDS>) {
    this.selectedGDSList = data;
  }

  getDepartureTime() {
    return this.departureTime;
  }

  setDepartureTime(data: Array<string>) {
    this.departureTime = data;
  }

  getReturnTime() {
    return this.returnTime;
  }

  setReturnTime(data: string) {
    this.returnTime = data;
  }

  getReturnDate() {
    return this.returnTime;
  }

  setReturnDate(data: Date) {
    this.returnDate = data;
  }

  setLowFareSearchValues(data: any) {
    this.lowFareSearchValues = data;
  }

  getLowFareSearchValues() {
    return this.lowFareSearchValues;
  }

  setLowFareSearchExtraValues(data: any) {
    this.lowFareSearchExtraValues = data;
  }

  getLowFareSearchExtraValues() {
    return this.lowFareSearchExtraValues;
  }

  setTraceId(id: string) {
    this.traceId = id;
  }

  getTraceId() {
    return this.traceId;
  }

  setMarkupRule(data: Array<MarkupRuleApplied>) {
    this.currentMarkUp = data;
  }

  getMarkupRule() {
    return this.currentMarkUp;
  }

  setMarkupRuleFlightCombined(data: Array<MarkupRuleApplied>) {
    this.currentMarkUpFlightCombined = data;
  }

  getMarkupRuleFlightCombined() {
    return this.currentMarkUpFlightCombined;
  }

  setpriceRecofirmation(data: boolean) {
    this.priceReconfirmation = data;
  }

  getpriceRecofirmation() {
    return this.priceReconfirmation;
  }

  setFromLocal(lowFare: LowFareSearchDto, gdsList: GDS[]) {
    if (gdsList != null && gdsList != undefined && gdsList.length > 0) {
      this.setSelectedGDSList(gdsList);
          }
    
    if (lowFare != null && lowFare != undefined /*&& this.hasExpiredLowFareSearch() == false*/) {
      if (lowFare.Origin != null && lowFare.Origin != undefined) {
        this.setOrigin(lowFare.Origin);
      }
      if (lowFare.Destination != null && lowFare.Destination != undefined) {
        this.setDestination(lowFare.Destination);
      }
      if (lowFare.Via != null && lowFare.Via != undefined) {
        this.setVia(lowFare.Via);
      }
      if (lowFare.permittedCabins != null && lowFare.permittedCabins != undefined) {
        this.setPermittedCabins(lowFare.permittedCabins);
      }
      if (lowFare.DepartureDate != null && lowFare.DepartureDate != undefined) {
        this.setDepartureDate(lowFare.DepartureDate);
      }
      if (lowFare.DepartureTime != null && lowFare.DepartureTime != undefined) {
        this.setDepartureTime(lowFare.DepartureTime);
      }
      if (lowFare.ReturnTime != null && lowFare.ReturnTime != undefined) {
        this.setReturnTime(lowFare.ReturnTime);
      }

      if (lowFare.NonStop != null && lowFare.NonStop != undefined) {
        this.setNonStop(lowFare.NonStop);
      }
      if (lowFare.Refundable != null && lowFare.Refundable != undefined) {
        this.setRefundable(lowFare.Refundable);
      }
      if (lowFare.ExtraDays != null && lowFare.ExtraDays != undefined) {
        this.setExtraDays(lowFare.ExtraDays);
      }
      if (lowFare.NumAdult != null && lowFare.NumAdult != undefined) {
        this.setNumAdult(lowFare.NumAdult);
      }
      if (lowFare.InfantsDOB != null && lowFare.InfantsDOB != undefined) {
        this.setInfantsDOB(lowFare.InfantsDOB);
      }
      if (lowFare.ChildsDOB != null && lowFare.ChildsDOB != undefined) {
        this.setChildsDO(lowFare.ChildsDOB);
      }

      let isVia = lowFare.Via?.find(v => v != null && v != undefined && v != "")?.length ?? 0;
      let flightType =
        lowFare.Origin.length == 1 && isVia == 0
          ? "One Way"
          : isVia == 0 &&
            lowFare.Origin.length == 2 &&
            lowFare.Destination.length == 2 &&
            lowFare.Origin[0] == lowFare.Destination[1] &&
            lowFare.Origin[1] == lowFare.Destination[0]
          ? "RoundTrip"
          : "Multi Flights";

      if (flightType != null && flightType != undefined) {
        this.setOneWay(false);
        this.setRoundTrip(false);
        this.setItineraryMultiple(false);

        switch (flightType) {
          case "One Way":
            this.setOneWay(true);
            break;
          case "RoundTrip":
            this.setRoundTrip(true);
            break;
          case "Multi Flights":
            this.setItineraryMultiple(true);
            break;
          default:
            break;
        }
      }
    }
      }

  setExpirationLowFareSearch(hours = 0, minutes = 0, seconds = 10) {
    const now = new Date();
    const nowMilliseconds = now.valueOf();
    const milliseconds = hours * 3600000 + minutes * 60000 + seconds * 1000;
    const nowPlusTime = new Date(nowMilliseconds + milliseconds);
    localStorage.setItem("lowFarePersistanceExpiration", JSON.stringify(nowPlusTime));
  }

  /*hasExpiredLowFareSearch(): boolean {
  let returnedValue = false;

  const expDate = new Date(JSON.parse(localStorage.getItem('lowFarePersistanceExpiration')));
  const now = new Date();
  const expirated = now >= expDate;

  if(expDate == null || expirated) {
    localStorage.removeItem('lowFareSearchPersistance');
    localStorage.removeItem('GDSListPersistance');
    localStorage.removeItem('lowFareRespPersistance');
    localStorage.setItem('lowFarePersistanceExpiration', JSON.stringify(new Date()));

    returnedValue = true
  }

  return returnedValue;
}*/

  setDisplayGDSListFromLocal(gdsList: GDS[]) {
    if (gdsList != null && gdsList != undefined) {
      this.setDisplayGDSList(gdsList);
    }
  }

  setSelectedGDSListFromLocal(gdsList: GDS[]) {
        if (gdsList != null && gdsList != undefined) {
      this.setSelectedGDSList(gdsList);
    }
  }
  getPassengerType(code: string){
    if (code === "CNN" || code === "CHILD" || code == 'INN' || code == 'CHD' || code === "UNN") {
      return 'child';
    }
    else if (code === "INF" || code === "HELD_INFANT" || code === "SEATED_INFANT" || code === 'ITF' || code === "INS" || code.includes('INFANT')) {
      return 'infant';
    }
    else if (code === "ADT" || code === "ADULT" || code === "ITX" || code === 'PFA' || code === 'YTH' || code == "IIT") {
      return 'adult';
    }
  }
}
