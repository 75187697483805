import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PaymentSuccessComponent } from "./payment-success/payment-success.component";
import { SigninOidcComponent } from "./signin-oidc/signin-oidc.component";
import { TranslationComponent } from "./translation/translation.component";
import { Error404Component } from "./error404/error404.component";
import { AuthGuard } from "@auth0/auth0-angular";

const routes: Routes = [
  {
    path: "",
    data: { title: "index", breadcrumb: "" },
    canActivate: [AuthGuard],
    loadChildren: () => import("./index/index.module").then(m => m.IndexModule),
  },
  {
    path: ":lang",
    component: TranslationComponent,
    data: { title: "Home", breadcrumb: "Home" },
    children: [
      {
        path: "",
        data: { title: "Home", breadcrumb: "" },
        canActivate: [AuthGuard],
        loadChildren: () => import("./home/home.module").then(m => m.HomeModule),
      },
      {
        path: "flights",
        data: { breadcrumb: "Search", canActivate: [AuthGuard] },
        loadChildren: () => import("./flights/flights.module").then(m => m.FlightsModule),
      },
      {
        path: "booking",
        data: { breadcrumb: "Booking" },
        canActivate: [AuthGuard],
        loadChildren: () => import("./booking/booking.module").then(m => m.BookingModule),
      },
      {
        path: "frecuently-passengers",
        data: { breadcrumb: "Frecuently Passengers List" },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./frecuently-passengers/frecuently-passengers.module").then(m => m.FrecuentlyPassengersModule),
      },
      {
        path: "user-settings",
        data: { breadcrumb: "User Settings" },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./user-change-password/user-settings.module").then(m => m.UserSettingsModule),
      },
      {
        path: "airbooklog",
        data: { breadcrumb: "Airbook Log List" },
        canActivate: [AuthGuard],
        loadChildren: () => import("./airbooklog/airbooklog.module").then(m => m.AirbooklogModule),
      },
      {
        path: "airpricelog",
        data: { breadcrumb: "Airprice Log List" },
        canActivate: [AuthGuard],
        loadChildren: () => import("./airpricelog/airpricelog.module").then(m => m.AirpricelogModule),
      },
      {
        path: "paymentlog",
        data: { breadcrumb: "Payment Log List" },
        canActivate: [AuthGuard],
        loadChildren: () => import("./paymentlog/paymentlog.module").then(m => m.PaymentlogModule),
      },
      {
        path: "logsearch",
        data: { breadcrumb: "Search Log List" },
        canActivate: [AuthGuard],
        loadChildren: () => import("./logsearch/logsearch.module").then(m => m.LogsearchModule),
      },
      {
        path: "log-error",
        data: { breadcrumb: "Errors Log List", expectedRole: "Administrator" },
        canActivate: [AuthGuard],
        loadChildren: () => import("./log-error/log-error.module").then(m => m.LogErrorModule),
      },
      {
        path: "fare-rule",
        data: { breadcrumb: "Fare Rule", expectedRole: "Administrator" },
        canActivate: [AuthGuard],
        loadChildren: () => import("./fare-rule-engine/fare-rule-engine.module").then(m => m.FareRuleEngineModule),
      },
      {
        path: "fare-rule-group",
        data: { breadcrumb: "Fare Rule Group List", expectedRole: "Administrator" },
        canActivate: [AuthGuard],
        loadChildren: () => import("./fare-rule-group-engine/fare-rule-group-engine.module").then(m => m.FareRuleGroupEngineModule),
      },
      {
        path: "prefered-blacklist-airlines-rules",
        data: { breadcrumb: "Prefered/Blacklist Airlines Rules List", expectedRole: "Administrator" },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./prefered-blacklist-airlines-rules/prefered-blacklist-airlines-rules.module").then(
            m => m.PreferedBlacklistAirlinesRulesModule
          ),
      },
      {
        path: "markup-rules",
        data: { breadcrumb: "Markup Rules List", expectedRole: "Administrator" },
        canActivate: [AuthGuard],
        loadChildren: () => import("./markup-rule/markup-rule.module").then(m => m.MarkupRuleModule),
      },
      {
        path: "ssr-air-rules",
        data: { breadcrumb: "SSR Air Rules List", expectedRole: "Administrator" },
        canActivate: [AuthGuard],
        loadChildren: () => import("./ssr-air-rules/ssr-air-rules.module").then(m => m.SSRAirRulesModule),
      },
      {
        path: "extra-service",
        data: { breadcrumb: "Extra Service List", expectedRole: "Administrator" },
        canActivate: [AuthGuard],
        loadChildren: () => import("./extra-service/extra-service.module").then(m => m.ExtraServiceModule),
      },
      {
        path: "payment-success",
        data: { breadcrumb: "Processing Booking" },
        canActivate: [AuthGuard],
        component: PaymentSuccessComponent,
      },
      {
        path: "branded-fare",
        data: { breadcrumb: "Branded Fare List", expectedRole: "Administrator" },
        canActivate: [AuthGuard],
        loadChildren: () => import("./branded-fare/branded-fare.module").then(m => m.BrandedFareModule),
      },
      {
        path: "stadistics",
        data: { breadcrumb: "Stadistics", expectedRole: "Administrator" },
        canActivate: [AuthGuard],
        loadChildren: () => import("./stadistics/stadistics.module").then(m => m.StadisticsModule),
      },
      {
        path: "404",
        component: Error404Component
      },
      {
        path: "**",
        redirectTo: "404"
      }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled"})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
