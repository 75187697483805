import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Observable, pipe, throwError } from "rxjs";
import { BaseService } from "./base.service";
import { AirBookRsp } from "../models/air-book-rsp";
import { AirBookReq } from "../models/air-book-req";
import { PaymentRsp } from "../models/payment-rsp";

@Injectable({
  providedIn: "root",
})
export class AirBookService extends BaseService {
  headers = new HttpHeaders({ "Content-Type": "application/json" });

  constructor(public http: HttpClient) {
    super();
  }

  getAirBook(airBookReq: AirBookReq): Observable<AirBookRsp> {
    return this.http.post<AirBookRsp>(this.apiUrl + "/flights/airBookAsync", airBookReq, { headers: this.headers });
  }

  sendPayment(airBookReq: AirBookReq): Observable<PaymentRsp> {
    return this.http.post<PaymentRsp>(this.apiUrl + "/payment/pay", airBookReq, { headers: this.headers });
  }
}
