import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.scss']
})
export class Error404Component {
  error404_1: string = "error404_1";
  error404_2: string = "error404_2";
  error404_3: string = "error404_2";
  isErrorImageTranslated: boolean = false;
  constructor( private router: Router, private translateService: TranslateService, 
    private route: ActivatedRoute) {}
  ngOnInit(){
    if (localStorage.hasOwnProperty("error404") && localStorage.getItem("error404") != null) {
      let error404 = localStorage.getItem("error404");
      this.error404_2 = error404;
      this.error404_1 = error404 + "_title";
      this.error404_3 = error404 + "_image";
      this.translateService.get('emptyStates.' + this.error404_3).subscribe((labelTranslated: string) => {
        if(labelTranslated.includes('emptyStates.')) this.error404_3 = "404-error.png";
        else this.error404_3 = labelTranslated;
        this.isErrorImageTranslated = true;
        
      }, error => {
        this.error404_3 = "404-error.png";
        this.isErrorImageTranslated = true;
      });
    } else {
      this.error404_3 = "404-error.png";
      this.isErrorImageTranslated = true;
    }
  }
  tryAgain() {
    window.history.back()
  }
}
