import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { distinctUntilChanged, filter } from "rxjs";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  footerPosition: boolean = true;

  constructor(private router: Router, private _translateService: TranslateService) {}

  ngOnInit() {
    this.changeBackground(this.router.url);

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        distinctUntilChanged()
      )
      .subscribe((event: NavigationEnd) => {
        this.changeBackground(event.urlAfterRedirects);
      });
  }

  changeBackground(currentRoute: string) {
    if (currentRoute == ("/" + this._translateService.currentLang) ||
        currentRoute == ("/" + this._translateService.currentLang + "/flights") ||
        currentRoute == ("/" + this._translateService.currentLang + "/404") ||
        currentRoute == ("/" + this._translateService.currentLang + "/user-change-password")) {
      this.footerPosition = true;
    } else {
      this.footerPosition = false;
    }
  }
}
