import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BackgroundClassService {

  private backgroundClassSubject = new BehaviorSubject<string>('white-background');
  backgroundClass$ = this.backgroundClassSubject.asObservable();

  setBackgroundClass(c: string) {
    this.backgroundClassSubject.next(c);
  }

  constructor() { }
}
