import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SharedModule } from "./shared/shared.module";
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgxSpinnerModule } from "ngx-spinner";
import { SliderModule } from "primeng/slider";
import { AutoCompleteModule } from "primeng/autocomplete";
import { DropdownModule } from "primeng/dropdown";
import { TooltipModule } from "primeng/tooltip";
import localeEn from "@angular/common/locales/en";
import { CommonModule, registerLocaleData } from "@angular/common";
import localeEs from "@angular/common/locales/es";
import localeNl from "@angular/common/locales/nl";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslationComponent } from "./translation/translation.component";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { PaymentSuccessComponent } from "./payment-success/payment-success.component";
import { HttpErrorInterceptor } from "./shared/services/httpErrorInterceptor";
import { Error404Component } from "./error404/error404.component";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular-ivy";
import { ErrorHandlerService } from "./shared/services/error-handler.service";
import { ChartModule } from 'primeng/chart';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { environment as env } from '../environments/environment';

registerLocaleData(localeEn, "en");
registerLocaleData(localeEs, "es");
registerLocaleData(localeNl, "nl");

@NgModule({
  declarations: [
    AppComponent,
    TranslationComponent,
    PaymentSuccessComponent,
    Error404Component
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    NgbModule,
    AppRoutingModule,
    SharedModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    SliderModule,
    AutoCompleteModule,
    DropdownModule,
    TooltipModule,
    ChartModule,
    AuthModule.forRoot({
      ...env.auth
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    })
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: "en" },
    NgbActiveModal,
    { provide: ErrorHandler, useClass: ErrorHandlerService}
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + Date.now());
}
