export interface ISidebarElement {
    name: string;
    route?: string;
    adminRequired: boolean;
    expanded: boolean;
    icon?: string;
    subElementList?: ISidebarElement[];
}

export const SidebarItems: ISidebarElement[] = [
  {
    name: "home",
    route: "/",
    adminRequired: false,
    icon: "home_sidebar.png",
    expanded: false
  },
  {
    name: "bookingFlight",
    route: "flights",
    adminRequired: false,
    icon: "flight_sidebar.png",
    expanded: false
  },
  {
    name: "bookingFlightDev",
    route: "flights/flightsFareRuleType",
    adminRequired: true,
    icon: "booking_engine_dev_sidebar.png",
    expanded: false
  },
  {
    name: "bookingList",
    route: "booking",
    adminRequired: false,
    icon: "booking_list_sidebar.png",
    expanded: false
  },
  {
    name: "frecuentlyPassengers",
    route: "frecuently-passengers",
    adminRequired: false,
    icon: "frequent_flyer_sidebar.png",
    expanded: false
  },
  {
    name: "rulesEngine",
    adminRequired: true,
    icon: "rules_engine_sidebar.png",
    expanded: false,
    subElementList: [
      {
        name: "fareRules",
        route: "fare-rule",
        adminRequired: true,
        expanded: false
      },
      {
        name: "fareRulesGroups",
        route: "fare-rule-group",
        adminRequired: true,
        expanded: false
      },
      {
        name: "preferedBlacklist",
        route: "prefered-blacklist-airlines-rules",
        adminRequired: true,
        expanded: false
      },
      {
        name: "markupRules",
        route: "markup-rules",
        adminRequired: true,
        expanded: false
      },
      {
        name: "ssrAirRules",
        route: "ssr-air-rules",
        adminRequired: true,
        expanded: false
      },
      {
        name: "extraService",
        route: "extra-service",
        adminRequired: true,
        expanded: false
      },
      {
        name: "brandedFare",
        route: "branded-fare",
        adminRequired: true,
        expanded: false
      }
    ]
  },
  {
    name: "logs",
    adminRequired: true,
    icon: "logs_sidebar.png",
    expanded: false,
    subElementList: [
      {
        name: "logsearch",
        route: "logsearch",
        adminRequired: true,
        expanded: false
      },
      {
        name: "airpricelog",
        route: "airpricelog",
        adminRequired: true,
        expanded: false
      },
      {
        name: "airbooklog",
        route: "airbooklog",
        adminRequired: true,
        expanded: false
      },
      {
        name: "paymentlog",
        route: "paymentlog",
        adminRequired: true,
        expanded: false
      },
      {
        name: "logerror",
        route: "log-error",
        adminRequired: true,
        expanded: false
      }
    ]
  },
  {
    name: "stadistics",
    route: "stadistics",
    adminRequired: true,
    icon: "stadistics_sidebar.svg",
    expanded: false
  }
]