<div class="copyright" [ngClass]="{'footerPosition': footerPosition}">
  <div class="container">
    <div class="row">
      <div class="col col-md-10 col-12">
        <span class="regular fs12 lh32">© 2024 Vuelandia. All Rights Reserved</span>
        <a class="regular lnk fs12 lh32" href="https://vuelandia.com/algemene-voorwaarden/">Terms and conditions<i></i></a>
        <a class="regular lnk fs12 lh32" href="https://vuelandia.com/privacy/">Privacy statement<i></i></a>
      </div>
      <div class="col col-md-2">
        <div class="social">
          <a href="https://www.facebook.com/VuelandiaNederland/" title="Facebook" class="facebook"></a>
          <a href="" title="Instagram" class="instagram"></a>
        </div>
      </div>
    </div>
  </div>
</div>
