import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  private spinnerSubject = new Subject<any>();
  subjectObservable$ = this.spinnerSubject.asObservable();

  constructor() { }

  show(): void {
    this.spinnerSubject.next(true);
    document.body.classList.add('no-scroll');
  }

  hide(): void {
    this.spinnerSubject.next(false);
    document.body.classList.remove('no-scroll');
  }
}
