import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { AirBookService } from "../shared/services/air-book.service";
import { StorageService } from "../shared/services/storege.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import swal from "sweetalert2";
import { AirBookReq } from "../shared/models/air-book-req";

@Component({
  selector: "app-payment-success",
  templateUrl: "./payment-success.component.html",
  styleUrls: ["./payment-success.component.scss"],
})
export class PaymentSuccessComponent implements OnInit {
  error = false;

  constructor(
    private router: Router,
    private storageService: StorageService,
    private airBookingService: AirBookService,
    private spinnerService: NgxSpinnerService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    sessionStorage.setItem(
      "authorizationResult_VuelandiaFlightsClient2",
      localStorage.getItem("authorizationResult_VuelandiaFlightsClient2")
    );
    this.spinnerService.show();
    this.getAirBook();
  }

  getAirBook() {
    const airBookRq: AirBookReq = JSON.parse(localStorage.getItem("AirbookRq"));

    console.log("Rq: ", airBookRq);

    this.airBookingService.getAirBook(airBookRq).subscribe(
      req => {
        console.log("Rsp -> ", req);
        this.spinnerService.hide();
        this.storageService.setAirBookRsp(req);
        this.storageService.setAirBookReq(airBookRq);

        if (req != null) {
          this.router.navigate(["/" + this.translateService.currentLang + "/booking/" + req.flightBookingId]);
          this.spinnerService.hide();
        }
      },
      error => {
        this.spinnerService.hide();
      }
    );
  }

  showError(message: string) {
    swal.fire({
      title: "Error",
      text: message,
      icon: "error",
    });
  }
}
