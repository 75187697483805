import { ErrorHandler, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {

  constructor() { }

  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    const browserAuthErrorMessage = /BrowserAuthError: redirect_in_iframe: Redirects are not supported for iframed or brokered applications/;
    const loginRequiredMessage = /Login required/;

    if (chunkFailedMessage.test(error.message) || browserAuthErrorMessage.test(error.message)) {
      window.location.reload();
    } else if (loginRequiredMessage.test(error.message)) {
      console.log("Login required")
    } else {
      console.error(error);
    }
  }
}
