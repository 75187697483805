import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, NavigationEnd, Params, Router } from "@angular/router";
import { distinctUntilChanged, filter } from "rxjs";
import { BackgroundClassService } from "../shared/services/background-class.service";
import { SpinnerService } from "../shared/services/spinner.service";

@Component({
  selector: "app-translation",
  templateUrl: "./translation.component.html",
  styleUrls: ["./translation.component.css"],
})
export class TranslationComponent implements OnInit {
  activeLang = "en";
  backgroundClass: string = "";
  greyBackground: string[] = ["/booking", "/flights/"];
  showSpinner: boolean = false;

  constructor(
    private _translate: TranslateService,
    private _activatedRoute: ActivatedRoute,
    private router: Router,
    private backgroundService: BackgroundClassService,
    private spinnerService2: SpinnerService
  ) {
    this._translate.setDefaultLang(this.activeLang);
  }

  ngOnInit() {
    this.spinnerService2.subjectObservable$.subscribe((show: boolean) => {
      this.showSpinner = show;
    })
    this.backgroundService.backgroundClass$.subscribe(c => {
      this.backgroundClass = c;
      //console.log("background-service", c)
    });

    this.changeBackground(this.router.url);

    this._activatedRoute.params.subscribe((params: Params) => {
      if (!params["lang"].includes("silentrenew")) {
        this._translate.use(params["lang"]);
        this._translate.setDefaultLang(params["lang"]);
        if (!localStorage.hasOwnProperty("lang")) localStorage.setItem("lang", this._translate.currentLang);
      }
    });

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        distinctUntilChanged()
      )
      .subscribe((event: NavigationEnd) => {
        this.changeBackground(event.urlAfterRedirects);
      });
  }

  changeBackground(currentRoute: string) {
    currentRoute = currentRoute.slice(3);
    if (currentRoute == ("") || currentRoute == ("/flights")) {
      this.backgroundClass = "clouds-background";
    } else if (this.greyBackground.some(route => currentRoute.includes(route))) {
      this.backgroundClass = "grey-background";
    } else {
      this.backgroundClass = "white-background"
    }
    //console.log("background-route", this.backgroundClass)
  }

  changeLanguage(lang) {
    this.activeLang = lang;
    this._translate.use(lang);
  }
}
