import { Component } from '@angular/core';
import { UserService } from './shared/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "FrontEnd-Flights";

  constructor(
    private readonly userService: UserService,
  ) {

  }

  ngOnInit(): void {
    this.userService.setStyle();
    this.checkVersioning();
  }
  
  checkVersioning()
  {
    var versionLocalStorage = localStorage.getItem("version");
    var actualVersion = environment.version;
    if(versionLocalStorage == null || versionLocalStorage == "undefined")
    {
      localStorage.clear();
      localStorage.setItem("version", environment.version);
      window.location.href = environment.originUrl;
    }
    else if(versionLocalStorage != actualVersion)
    {
      localStorage.clear();
      localStorage.setItem("version", environment.version);
      window.location.href = environment.originUrl;
    }
  }

}
