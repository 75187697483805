<div class="content page">
  <div class="empty-state-section">
    <div>
      <img src="../../assets/images/{{ error404_3 }}" *ngIf="isErrorImageTranslated">
      <div>{{ "emptyStates." + error404_1 | translate }}</div>
      <div>{{ "emptyStates." + error404_2 | translate }}<br>{{ "emptyStates.error404_3" | translate }}</div>
      <div class="button-secondary" (click)="tryAgain()">{{ "emptyStates.tryAgain" | translate }}</div>      
    </div>
  </div>
</div>

